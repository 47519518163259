import React, {useState, useEffect} from "react"

import Layout from '../../components/layout';
import Navbar from '../../components/navbar'
import Footer from '../../components/footer'
import FreeTrial from "../../components/free-trial";
import SEO from "../../components/seo";

import LeftFeature from '../../components/left-feature';
import FeaturePararaph from '../../components/feature-paragraph'

import calculatorSrc from '../../images/calculator.svg';
import rotaPlannerSrc from '../../images/rota-planner.png';


const LabourCalculator = () => {

	let [labourCosts, setLabourCosts] = useState(null);
	let [revenue, setRevenue] = useState(null);

	let [labourPercentage, setLabourPercentage] = useState(null);

	let [clipboardBtnText, setClipboardBtnText] = useState(null);

	useEffect(() => {
		if(labourCosts != null || revenue != null){
			let labourPercentage = ((labourCosts / revenue) * 100).toFixed(2);
			if(labourPercentage !== undefined && isFinite(labourPercentage)){
				setLabourPercentage(labourPercentage);
			}else{
				setLabourPercentage(null);
			}
		}else{
			setLabourPercentage(null);
		}
	}, [labourCosts, revenue])

	return (
		<div className="mt-8 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
			<div>
	          <label htmlFor="labour_costs" class="block text-sm font-medium leading-5 text-gray-600">Total Labour Costs (Over a Period)</label>
	          <div className="mt-1 relative">
	            <input id="labour_costs" min="0" value={labourCosts} onChange={(e) => {

	            	if(e.target.value === ''){
	            		setLabourCosts(null);
	            	}else{
		            	const val = Math.max(0, parseInt(e.target.value, 10));
		            	console.log(val);
		            	setLabourCosts(val)
		            }
	            }} type="number" placeholder="Enter labour costs" className="form-input rounded-md border-highlight-400 border-2 py-3 px-4 block w-full focus:outline-none transition ease-in-out duration-150" />
	          </div>
	        </div>
	        <div>
	          <label htmlFor="revenue" className="block text-sm font-medium leading-5 text-gray-600">Sales/Revenue (Over Same Period)</label>
	          <div class="mt-1 relative">
	            <input id="revenue" value={revenue} onChange={(e) => {
	            	if(e.target.value === ''){
	            		setRevenue(null);
	            	}else{
	            		const val = Math.max(0, parseInt(e.target.value, 10));
	            		setRevenue(val);
	            	}
	            }} type="number" placeholder="Enter total revenue" className="form-input rounded-md border-highlight-400 border-2 py-3 px-4 block w-full focus:outline-none transition ease-in-out duration-150" />
	          </div>
	        </div>
	        {labourPercentage != null && 
	        	<div className="col-span-1 lg:col-span-2 text-center">
		        	<h2 className="mt-2 mb-8 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">{labourPercentage != null && !isNaN(labourPercentage) && `${labourPercentage}%`}</h2>
		        	<button type="button" onClick={() => {
		        		navigator.clipboard.writeText(`${labourPercentage}%`);
		        		setClipboardBtnText('Copied')
		        		setTimeout(() => {
		        			setClipboardBtnText(null);
		        		}, 1000)
		        	}} className="inline-flex items-center justify-center px-6 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-highlight-600 focus:outline-none focus:outline-none transition ease-in-out duration-150">
		              {clipboardBtnText == null && <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" /></svg>} {clipboardBtnText != null ? clipboardBtnText : ' Copy to clipboard'}
		            </button>
		        </div>
		    }
	    </div>
	)
}

const LabourPercentageCalculator = () => (
  <Layout>
    <SEO 
    	title="Labour Percentage Calculator for Shift Based Companies "
    	description="Calculate your labour cost percentage with our free calculator. Enter in labour costs and revenue/sales to get an labour cost percentage instantly with our handy calculator."
    	 />
    <Navbar />
    <div className="bg-gray-50">
		<div className="py-16 bg-gray-50 overflow-hidden lg:py-24">
		  <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
		     <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
			    <div className="mb-8 lg:mb-0 lg:col-start-1 flex flex-col">
			    	<div className="flex justify-center">
			    		<img className="w-1/4" src={calculatorSrc} alt="Calculator" />
			    	</div>
					<LabourCalculator />
			    </div>
			    <div className="lg:col-start-2 lg:pl-8">
			      <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
			        <p className="leading-6 text-highlight-600 font-semibold tracking-tight uppercase">Labour Cost Percentage Calculator</p>
			        <h1 className="mt-2 mb-8 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">Calculate Labour Cost Percentage with our free calculator.</h1>
			        <div className="prose text-gray-500">
			          <p>As the old saying goes, time is money and that's especially true in a shift-based business! One important metric to watch is your Labour Cost Percentage.</p>
			          <p>What is Labour Cost Percentage? Well it's a simple metric that divides your cost of your labour for a time period (say a week) over the sales or revenue for that week.</p>
					  <p>For example, if you had <strong>£1000</strong> in labour costs in a given week and <strong>£2000</strong> in sales in that week, your labour cost percentage would be <strong>50%</strong>.</p>
					  <p>The goal for most business owners is to make sure this labour cost is never exceeding 100% and to keep it as low as possible, so that you are making an expected level of profits in your business.</p>
			          <p>You can calculate your labour cost percentage in your business with our <strong>free</strong> calculator!</p>
			        </div>
			      </div>
			    </div>
			  </div>
			  <LeftFeature 
			    	sectionTitle="Forecast your labour costs" 
			    	title="Move to Digital Rota Planning Software" 
			    	body={
			    		<div>
					        <FeaturePararaph>
					          The above calculation is just one of many things you have to keep on top of when managing labour in your business.
					        </FeaturePararaph>
					        <FeaturePararaph>
					        	If you had a few minutes, we'd love for you to check out TimeKeeper. Our simple solution allows you to go digital with your rotas, employee timesheets and more. Helping keep an eye on your business while saving you time and money in the process!
					        </FeaturePararaph>
					    </div>
			    	}
			    	imgSrc={rotaPlannerSrc}
			    	includeShadow={true}
			    	imgAlt="Move to Rota Planning Software with Timesheets"
			    />
		  </div>
		</div>
	  	
	</div>
	<FreeTrial showDemoBooking={true} part1={<span>Ready to make the first step to <span className="text-highlight-600">digital</span> <br /> shift rotas?</span>} />
	<Footer/>
  </Layout>
)

export default LabourPercentageCalculator;
